import { Colorful, ColorResult } from '@uiw/react-color';
import { useEffect, useState } from 'react';
import { sprintf } from 'sprintf-js';
import './App.css';

const PLASMA_LEDS = 50

const PLASMA_SERVER = ''
// const PLASMA_SERVER = 'http://localhost:3000'
// const PLASMA_SERVER = 'http://quarryst.hurst-frost.net'

let serverLastSent = ''
let timer: NodeJS.Timeout|undefined

function App() {
  const [hex, setHex] = useState('#FFFFFF');
  const [colour, setColour] = useState<ColorResult>();

  function random() {
    for (let led = 0; led < PLASMA_LEDS; led++) {
      fetch(`${PLASMA_SERVER}/plasma`).then(r => r.json()).then(res => {
        fetch(`${PLASMA_SERVER}/plasma/${led}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            hue: Math.floor(Math.random() * 360),
            saturation: 0.5
          })
        })
      })
    }
  }

  const carbon = () => {
    mode('carbon')
  }

  const weather = () => {
    mode('weather')
  }

  const rainbow = () => {
    mode('rainbow')
  }

  const mode = (mode: 'carbon'|'weather'|'rainbow') => {
    fetch(`${PLASMA_SERVER}/plasma/${mode}`, {
      method: 'POST'
    })
  }

  function syncWithServer() {
    fetch(`${PLASMA_SERVER}/plasma`).then(r => r.json()).then(res => {
      setHex(sprintf('#%02x%02x%02x', res.r, res.g, res.b))
    })
  }

  useEffect(() => {
    window.addEventListener('focus', syncWithServer)
    syncWithServer()
  }, [])

  useEffect(() => {
    if (!timer && colour) {
      timer = setTimeout(() => {
        const newValue = sprintf('#%02x%02x%02x', colour.rgb.r, colour.rgb.g, colour.rgb.b)
        if (serverLastSent !== newValue) {
          serverLastSent = newValue
          fetch(`${PLASMA_SERVER}/plasma/${colour.rgb.r}/${colour.rgb.g}/${colour.rgb.b}`, { method: 'PUT' })
        }
        timer = undefined
      }, 100)
    }
  }, [colour])
  
  return (
    <div className="App" style={{margin:'auto'}}>
      <Colorful
        style={{width:'100%'}}
        color={hex}
        disableAlpha={true}
        onChange={(color) => {
          setHex(color.hex)
          setColour(color)
        }}
      />
      <div className='buttons'>
        <button onClick={random}>random</button>
        <button onClick={rainbow}>rainbow</button>
        <button onClick={carbon}>carbon</button>
        <button onClick={weather}>weather</button>        
      </div>

    </div>
  );
}

export default App;
